<template>
  <div class="home">
    <img class="logo" alt="Logo Toulouse" src="@/assets/images/logo-toulouse-white.svg">
    <img class="circle circle-1" src="@/assets/images/circle2.png" alt="">
    <img class="circle circle-2" src="@/assets/images/circle.png" alt="">
    <!-- <img class="circle circle-3" src="@/assets/images/circleHome.svg" alt=""> -->
    <img src="@/assets/images/homeLeft.png" class="img-left" alt="">
    <img :src="require(`../assets/images/home-illustration.${$i18n.locale}.png`)" class="svg-center" alt="">
    <img src="@/assets/images/homeRight.png" class="img-right" alt="">
    <div class="home-animation">
      <video
      :src="require(`@/assets/videos/decouvrirQuestionnaire.webm`)"
      muted="true"
      autoplay="true"
      loop="true"
      :poster="require(`@/assets/videos/decouvrir.png`)"
      />
      <video
      :src="require(`@/assets/videos/activitesQuestionnaire.webm`)"
      muted="true"
      autoplay="true"
      loop="true"
      :poster="require(`@/assets/videos/activites.png`)"
      />
    </div>

    <button class="btn" @click="$emit('startClick')">{{$t('home.start')}}
      <i class="icon icon-arrow-right"></i>
    </button>
    <div class="btns-group">
      <button
        class="util-btn lang-btn"
        @click="onLangClick(lang.locale)"
        v-for="lang in availableLangs"
        :key="lang.locale"
      >
        <span class="icon-container">
          <span class="flag"><img :src="require(`../assets/images/flags/${lang.locale}.svg`)" alt=""></span>
        </span>
        <span class="label">{{lang.locale}}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    availableLangs() {
      return this.availableLangQuestions.filter(lang => lang !== this.$i18n.locale).map(langId => ({
        locale: langId,
      }));
    },
    ...mapGetters({ availableLangQuestions: 'availableLangs' }),
  },
  methods: {
    onLangClick(locale) {
      this.$i18n.locale = locale;
    },
  },
  data() {
    return {
      langs: {
        fr: 'FR',
        en: 'EN',
        es: 'SP',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .logo {
    position: absolute;
    top: 90px;
    left: 50%;
    width: 190px;
    max-width: 100%;
    height: auto;
    transform: translateX(-50%);
  }

  .circle {
    display: inline-block;
    position: absolute;
    // animation: circles 20s infinite linear;
  }

  .circle-1 {
    top: 140px;
    right: 193px;
    width: 390px;
  }

  .circle-2 {
    top: 520px;
    left: 196px;
    width: 400px;
    // rotate: -360deg;
  }

  .circle-3 {
    right: 616px;
    bottom: 178px;
    width: 80px;
  }

  .img-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 500px;
  }

  .svg-center {
    position: absolute;
    top: 47%;
    left: 50%;
    width: 590px;
    transform: translate(-50%, -50%);
  }

  .img-right {
    position: absolute;
    top: 320px;
    right: 0;
    width: 495px;
  }

  .home-animation {
    video {
      position: absolute;
      top: 203px;
      right: 266px;
      width: 240px;
    }

    :last-child {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 366px;
      left: 335px;
      width: 190px;
    }
  }

  .btn {
    position: absolute;
    bottom: 120px;
    left: 50%;
    width: 400px;
    height: 120px;
    margin: auto;
    transform: translateX(-50%);
    background-color: $turquoise;
    font-size: 29px;
  }

  .btns-group {
    display: flex;
    position: relative;
    align-items: center;
    margin-top: auto;
    margin-left: auto;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      right: -1px;
      bottom: -1px;
      width: 330px;
      height: 185px;
      background-image: url('~@/assets/images/langback.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }

    button:first-child {
      margin-right: 10px;
    }

    button:last-child {
      margin-right: 40px;
    }
  }

  .util-btn {
    z-index: 2;
    margin: 20px;
    border: 0;
    appearance: none;
    background: transparent;
    color: $black;
    font-size: 1.7rem;
    font-weight: $regular-weight;
    text-transform: uppercase;
  }

  .lang-btn {
    .flag {
      display: block;
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
      overflow: hidden;
      border-radius: 50%;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}

@keyframes circles {
  0% {
    transform: rotate(0deg) scale(1);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(-180deg) scale(1);
    animation-timing-function: ease-out;
  }

  100% {
    transform: rotate(-360deg) scale(1);
    animation-timing-function: ease-out;
  }
}
</style>
