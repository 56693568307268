<template>
  <transition name="modal">
    <div class="modal-container" v-if="visible" :class="{'modal-open': visible}">
      <div class="overlay" @click="close"></div>
      <div class="modal">
        <button class="close" @click="close"><span class="cross"></span></button>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.$emit('close');
    },
    open() {
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  display: none;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  padding: 40px 60px;

  &.modal-open {
    display: flex;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($true-black, 0.5);
}

.modal {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 30px;
  background-color: $white;
  box-shadow: 0 10px 40px rgba(32, 32, 32, 0.2);
}

.modal-header {
  margin-bottom: $spacing;
  padding-right: 90px;
}

::v-deep .modal-label {
  margin: 0;
  color: $grey-alt;
  text-transform: uppercase;

  .icon {
    margin-right: 15px;
    color: $pink;
  }
}

.close {
  position: absolute;
  z-index: 20;
  top: 35px;
  right: 35px;
  width: 50px;
  height: 50px;
  padding: 0;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  color: $pink;

  .cross {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    vertical-align: middle;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: currentColor;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity $transition-duration ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
