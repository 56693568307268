import axios from 'axios';
import config from '@/config';

export default class WordpressAjax {
  static get(action) {
    return axios.get(`${config.API_URL}/${config.API_AJAX_PATH}`, {
      params: {
        action,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }

        return Promise.reject();
      });
  }

  static post(data) {
    let params = data;

    if (typeof params === 'object') {
      params = Object.keys(params).map(key => `${key}=${typeof data[key] === 'string' ? data[key] : JSON.stringify(data[key])}`).join('&');
    }

    return axios.post(`${config.API_URL}/${config.API_AJAX_PATH}`, params)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }

        return Promise.reject();
      });
  }
}
