<template>
  <div class="matrix-question">
    <div class="line" v-for="line in lines" :key="line.id">
      <p class="label">{{line.display_name}}</p>
      <div class="choices" :class="{ 'no-answer': !currentValue.value[line.id] }">
        <div v-for="choice in choices" :key="choice.id" class="choice">
          <input
            type="radio"
            :name="`${questionID}-${line.id}`"
            :id="`${questionID}-${line.id}-${choice.id}`"
            :value="choice.id"
            v-model="currentValue.value[line.id]"
          >
          <label class="pill small number" :for="`${questionID}-${line.id}-${choice.id}`">{{choice.display_name}}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questionID: {
      required: true,
    },
    value: Object,
    questionLabel: {
      type: String,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
    lines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentValue: false,
    };
  },
  watch: {
    'currentValue.value'(newValue) {
      this.$emit('input', { value: newValue });
    },
  },
  created() {
    let currentValue = false;

    if (this.value) {
      currentValue = this.value;
    } else {
      currentValue = {
        value: {},
      };
    }

    this.lines.forEach((line) => {
      if (!currentValue.value[line.id]) {
        currentValue.value[line.id] = false;
      }
    });

    this.currentValue = currentValue;
  },
};
</script>

<style lang="scss" scoped>
.matrix-question {
  width: 100%;
  margin-top: 90px;

  .line {
    margin-bottom: 20px;

    .label {
      margin-bottom: 20px;
      color: $white;
    }

    .choices:not(.no-answer) {
      .choice {
        .pill {
          opacity: 0.4;
        }

        input {
          &:checked {
            ~ .pill {
              opacity: 1;
              background-color: $white;
              color: $grey;
            }
          }
        }

        &.selected {
          .pill {
            opacity: 1;
            background-color: $white;
            color: $grey;
          }
        }
      }
    }
  }
}

.choices {
  display: flex;
}

.choice {
  margin-right: 25px;
  margin-bottom: 25px;

  input {
    display: none;
  }

  .pill {
    transition: opacity $transition-duration ease;
  }
}
</style>
