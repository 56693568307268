<template>
  <Modal :visible="visible" ref="modal">
    <p class="surtitle">{{label}}</p>

    <div class="input-container">
      <div
        class="grow-wrap"
        v-if="type === 'textarea'"
        :data-replicated-value="newValue"
      >
        <textarea
          name="name"
          id="name"
          class="textarea"
          :placeholder="placeholder"
          v-model="newValue"
          disabled
        ></textarea>
      </div>

      <input
        :type="type"
        name="name"
        id="name"
        class="input"
        :placeholder="placeholder"
        v-model="newValue"
        disabled
        v-else
      >
      <div class="cross" @click="emptyInput" v-if="newValue !== ''"></div>
    </div>

    <Keyboard
      :layout="keyboardLayout"
      :input="newValue"
      :keyboardAllowEnter="keyboardAllowEnter"
      @submit="onSubmit"
      @onChange="onInputChange"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal';
import Keyboard from '@/components/Keyboard';

export default {
  data() {
    return {
      visible: false,
      newValue: this.value ? this.value : '',
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    keyboardLayout: {
      type: String,
      required: true,
    },
    keyboardAllowEnter: {
      type: Boolean,
      default: false,
    },
    label: String,
    placeholder: String,
  },
  components: {
    Modal,
    Keyboard,
  },
  watch: {
    value(newVal) {
      this.newValue = newVal;
    },
  },
  methods: {
    open(value) {
      this.newValue = value;
      this.$refs.modal.open();
    },
    onSubmit() {
      this.$refs.modal.close();
      this.emitNewValue();
    },
    emitNewValue() {
      this.$emit('valueUpdate', this.newValue);
    },
    onInputChange(value) {
      this.newValue = value;
    },
    emptyInput() {
      this.newValue = '';
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal {
  display: flex;
  flex-direction: column;
  padding: 90px 60px $keyboard-height + 50px;
  background-color: $grey;
  color: $white;

  .input-container {
    margin-top: auto;
  }

  .close {
    color: $white;
  }
}

%input {
  padding: 0;
  padding-right: 80px;
  padding-bottom: 10px;
  border: 0;
  // border-bottom: 2px solid;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  background-color: transparent;
  color: $white;
  font-weight: $light-weight;
  line-height: 1.1;

  &:focus {
    outline: none;
  }

  @include placeholder {
    color: $rgbaWhite;
  }
}

.input-container {
  position: relative;
  width: 100%;

  .input {
    @extend %input;
    width: 100%;
    font-size: 9.5rem;
  }

  .cross {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    vertical-align: middle;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: $white;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.grow-wrap {
  display: grid;

  &::after {
    content: attr(data-replicated-value) " ";
    visibility: hidden;
    white-space: pre-wrap;
  }

  & > textarea {
    overflow: hidden;
    resize: none;
  }

  & > textarea,
  &::after {
    @extend %input;
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    padding-bottom: 20px;
    font-size: 3.5rem;
    line-height: 1.5;
  }
}
</style>
