<template>
  <div id="app" @click="onInteraction" @touchend="onInteraction">
    <button
      class="home-btn"
      @click="goToHome"
      :class="{ highlight: step === 'finish', hidden: step === 'home'}"
    >
      <i class="icon icon-home"></i>
    </button>

    <Home
      v-if="step === 'home' && questions.length > 0"
      @startClick="startQuestionnaire"
    ></Home>

    <div class="questions" v-if="step === 'questions'">
      <transition
        name="slide-left"
        mode="out-in"
      >
        <Question
          v-for="(question, index) in questions"
          :key="question.id"
          :question="question"
          v-model="answers[index]"
          v-if="currentQuestion === index"
        ></Question>
      </transition>

      <div class="pass-tourisme" :class="{visible: showPass && questions[currentQuestion].showPass }">
        <p class="title">Pass Tourisme</p>
        <div class="container-img">
          <img src="@/assets/images/passTourisme.png" alt="">
        </div>
        <img class="fleche-img" src="@/assets/images/fleche.png" alt="">
        <p class="subtitle">{{$t('pass-tourisme.available')}}</p>
        <p class="txt">{{$t('pass-tourisme.text')}}</p>
      </div>

      <div class="footer">
        <div class="avancement">
          <div class="value">
            <button class="circle-btn" @click="previousQuestion" v-show="currentQuestion > 0">
              <i class="icon icon-arrow-left"></i>
            </button>
            <span class="current-value">{{ currentQuestion + 1}}</span>
            <span class="separator">/</span>
            <span class="total">{{questions.length}}</span>
          </div>
          <div class="bar">
            <span class="progress" :style="{ width: getPercentage }"></span>
          </div>
        </div>

        <button
          class="btn turquoise next-btn"
          @click="onNextClick"
          :class="{ visible: isCurrentQuestionValid }"
        >
          <span v-if="currentQuestion === questions.length - 1">{{$t('last-question-button')}}</span>
          <span v-else>{{$t('next')}}</span>
          <i class="icon icon-arrow-right"></i>
        </button>
        <!-- <button @click="submitData">Submit</button> -->
      </div>
    </div>

    <Finish
      v-if="step === 'finish'"
      :answers="answers"
      @homeClick="goToHome"
    />

    <ResetModal
      ref="resetModal"
      @back="goToHome"
      @cancel="onInteraction"
    />

    <div class="no-internet-modal" v-if="noInternetConnexion">
      <div class="bloc">
        <h2 class="modal-title">Erreur de connexion</h2>
        <p class="text">Il semblerait que la connexion internet ne fonctionne pas.<br>Veuillez vous rapprocher d'un membre de l'office du tourisme.</p>
        <p class="text small">It seems that the internet connection is not working. <br>Please get in touch with a member of the tourist office.</p>
      </div>
    </div>

    <StandBy v-if="standbyData && standbyData.disabled === true" :display="standbyData.affichage" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Home from '@/components/Home';
import Question from '@/components/Question';
import Finish from '@/components/Finish';
import ResetModal from '@/components/modals/ResetModal';
import StandBy from '@/components/StandBy';
import WordpressAjax from '@/wordpress-ajax';
import config from '@/config';

export default {
  name: 'app',
  components: {
    Home,
    Question,
    Finish,
    ResetModal,
    StandBy,
  },
  data() {
    return {
      currentQuestion: 0,
      step: 'home',
      answers: [],
      delay: null,
      interval: null,
      showPass: null,
      showPassTimeout: null,
      noInternetConnexion: false,
      standbyData: false,
    };
  },
  computed: {
    ...mapGetters({ questions: 'questionsOfCurrentLang' }),
    getPercentage() {
      return `${(this.currentQuestion + 1) / (this.questions.length + 1) * 100}%`;
    },
    isCurrentQuestionValid() {
      const currentAnswer = this.answers[this.currentQuestion];

      if (currentAnswer && typeof currentAnswer.value === 'object') {
        const currentAnswerValue = currentAnswer.value;
        let isValid = true;

        Object.keys(currentAnswerValue).forEach((key) => {
          isValid = isValid && !!currentAnswerValue[key];
        });

        return isValid;
      }

      return !!currentAnswer;
    },
  },
  methods: {
    ...mapActions(['getQuestionsFromAPI']),
    onInteraction() {
      this.resetTimer();
    },
    showResetModal() {
      this.$refs.resetModal.open();
    },
    resetTimer() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      if (this.step === 'home' || !config.enableTimer) {
        return;
      }

      this.delay = config.popupShowDelay;

      this.interval = setInterval(() => {
        if (this.delay <= 0) {
          clearInterval(this.interval);
          this.showResetModal();
          return;
        }
        this.delay -= 1;
      }, 1000);
    },
    goToHome() {
      this.submitData();
      this.step = 'home';
    },
    startQuestionnaire() {
      this.currentQuestion = 0;
      this.answers = [];
      this.step = 'questions';
    },
    goToFinish() {
      this.step = 'finish';
      this.submitData();
    },
    onNextClick() {
      if (this.currentQuestion === this.questions.length - 1) {
        this.goToFinish();
        return;
      }

      if (this.showPassTimeout) {
        clearTimeout(this.showPassTimeout);
      }

      this.showPass = false;

      this.showPassTimeout = setTimeout(() => {
        this.showPass = true;
      }, 2000);
      this.currentQuestion += 1;
    },
    previousQuestion() {
      if (this.currentQuestion === 0) {
        return;
      }
      this.currentQuestion -= 1;
    },
    submitData() {
      if (this.answers.length <= 0 || this.currentQuestion < 1) {
        return false;
      }

      const data = {
        action: 'add_records',
        data: {
          lang: this.$i18n.locale,
          answers: [],
        },
      };

      this.answers.forEach((answer, index) => {
        const question = this.questions[index];

        const baseAnswer = {
          qid: question.id,
          type: question.type,
        };

        if (Array.isArray(answer.value)) {
          answer.value.forEach((value) => {
            data.data.answers.push({
              ...baseAnswer,
              value,
            });
          });
        } else if (answer.value instanceof Date) {
          data.data.answers.push({
            ...baseAnswer,
            value: answer.value,
          });
        } else if (typeof answer.value === 'object') {
          Object.keys(answer.value).forEach((key) => {
            data.data.answers.push({
              ...baseAnswer,
              line: key,
              value: answer.value[key],
            });
          });
        } else {
          let value = answer.value;

          if (answer.extra) {
            const extra = answer.extraText ? answer.extraText : answer.extra;

            if (value === 'plus') {
              value = extra;
            } else {
              value += ` - ${extra}`;
            }
          }

          data.data.answers.push({
            ...baseAnswer,
            value,
          });
        }
      });

      console.log(data);
      return WordpressAjax.post(data);
    },
  },
  mounted() {
    const body = document.querySelector('body');

    Array.from(body.querySelectorAll('.scroll-area .inner-scroll-area')).forEach((scrollArea) => {
      scrollArea.onscroll = this.onInteraction;
    });

    window.onscroll = this.onInteraction;

    this.resetTimer();

    window.addEventListener('online', () => {
      this.noInternetConnexion = false;
    });

    window.addEventListener('offline', () => {
      this.noInternetConnexion = true;
    });

    return Promise.all([
      WordpressAjax.get('get_gestion_tablettes'),
      this.getQuestionsFromAPI('fr'),
      this.getQuestionsFromAPI('en'),
      this.getQuestionsFromAPI('es'),
    ])
      .then(([gestionTablettes]) => {
        if (gestionTablettes.disabled) {
          this.standbyData = gestionTablettes;
        }
      })
      .catch(error => console.error(error));
  },
};
</script>

<style lang="scss">
@import './scss/main.scss';

#app {
  position: relative;
  width: 1800px;
  height: 1200px;
  overflow: hidden;
  background: linear-gradient(to bottom left, rgba(169, 43, 141, 0.92) 0%, rgba(233, 47, 137, 0.92) 35.94%, rgba(233, 36, 45, 0.92) 70.83%, rgba(240, 120, 48, 0.92) 100%);
}

.home-btn {
  position: absolute;
  z-index: 15;
  top: 30px;
  left: 30px;
  width: 100px;
  height: 100px;
  transition: color $transition-duration ease, opacity $transition-duration ease;
  border: 2px solid;
  border-radius: 50%;
  background: transparent;
  color: $rgbaWhite;

  &.highlight {
    color: $white;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .icon {
    font-size: 5rem;
  }
}

.questions {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 90px;
}

.pass-tourisme {
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  flex-direction: column;
  width: 250px;
  padding: 27px 40px;
  transform: translate(120%, -50%);
  transition: transform $transition-duration ease;
  border-radius: 30px 0 0 30px;
  background-color: rgba(0, 0, 0, 0.25);

  &.visible {
    transform: translate(0, -50%);
  }

  .fleche-img {
    position: absolute;
    top: 57%;
    left: -20px;
    width: 55px;
  }

  .title {
    max-width: 150px;
    margin: auto;
    margin-bottom: 20px;
    color: $yellow;
    font-size: 2.7rem;
    font-weight: $bold-weight;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
  }

  .subtitle {
    margin-top: 20px;
    padding: 0 10px;
    color: $white;
    font-size: 2.7rem;
    font-weight: $regular-weight;
    line-height: 1.2;
    text-align: center;
  }

  .txt {
    margin: 0 -15px;
    color: $white;
    font-size: 1.5rem;
    text-align: center;
  }

  .container-img {
    position: relative;
    width: 260px;
    height: 172px;
    margin-left: -60px;

    img {
      display: block;
      width: 100%;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 0 150px 70px 150px;

  .avancement {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 90px;
    margin-right: 35px;
    margin-left: auto;

    .value {
      display: flex;
      align-items: center;
      margin: auto 0 auto auto;

      .circle-btn {
        width: 54px;
        height: 54px;
        margin-right: 0;
        border: 2px solid $rgbaWhite;
        background-color: transparent;
      }

      .current-value {
        display: block;
        width: 40px;
        margin-left: 40px;
        color: $white;
        font-size: 5.5rem;
        font-weight: $book-weight;
      }

      .separator {
        margin: 0 15px;
      }

      .total,
      .separator {
        margin-top: 15px;
        color: $rgbaWhite;
        font-size: 2.5rem;
        font-weight: $regular-weight;
      }
    }

    .bar {
      position: relative;
      width: 100%;
      height: 4px;
      background-color: $rgbaWhite;

      .progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        transition: width $transition-duration ease;
        background-color: $white;
      }
    }
  }

  .btn {
    flex: 0 0 auto;
    width: 500px;
  }
}

.next-btn {
  transition: opacity $transition-duration ease;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.answers {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  background-color: rgba($white, 0.4);
  font-size: 1.5rem;
  font-weight: $book-weight;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  overflow: hidden;
  transition-property: height, opacity, transform;
  transition-duration: $transition-duration-fast;
  transition-timing-function: ease;
}

.slide-left-enter,
.slide-right-leave-active {
  transform: translate(20px, 0);
  opacity: 0;
}

.slide-left-leave-active,
.slide-right-enter {
  transform: translate(-20px, 0);
  opacity: 0;
}

.no-internet-modal {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $gradient-toulouse;

  .bloc {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 900px;
    padding: 90px 50px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    background-color: $white;
    text-align: center;

    .modal-title {
      margin-bottom: 40px;
      font-size: 6rem;
      font-weight: $light-weight;
      line-height: 1.2;
    }

    .text {
      color: $grey;
      font-size: 2.5rem;
      font-weight: $book-weight;

      &.small {
        margin-top: 40px;
        color: $grey-alt;
        font-size: 2.5rem;
      }
    }
  }
}
</style>
