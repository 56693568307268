<template>
  <Modal :visible="visible" ref="modal">
    <Datepicker
      :inline="true"
      monday-first
      v-model="newValue"
      :language="currentLocale"
      format="yyyy-MM-dd"
    ></Datepicker>

    <button class="btn" @click="onSubmit" :disabled="!newValue" :class="visible">{{$t('valider')}} <i class="icon icon-arrow-right"></i></button>
  </Modal>
</template>

<script>
import { en, es, fr } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import Modal from '@/components/modals/Modal';

export default {
  components: {
    Datepicker,
    Modal,
  },
  data() {
    return {
      visible: false,
      newValue: this.value ? this.value : 4,
      locales: {
        en, es, fr,
      },
    };
  },
  computed: {
    currentLocale() {
      return this.locales[this.$i18n.locale];
    },
  },
  watch: {
    value(newVal) {
      this.newValue = newVal;
    },
  },
  methods: {
    open(value) {
      this.newValue = value;
      this.$refs.modal.open();
    },
    onSubmit() {
      this.$refs.modal.close();
      this.emitNewValue();
    },
    emitNewValue() {
      this.$emit('valueUpdate', this.newValue);
    },
    onInputChange(value) {
      this.newValue = value;
    },
    emptyInput() {
      this.newValue = '';
    },
    plusClick() {
      this.newValue += 1;
    },
    minusClick() {
      if (this.newValue === 4) {
        return;
      }
      this.newValue -= 1;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 750px;
  height: 880px;
  padding: 80px;
  text-align: center;

  .surtitle {
    margin-top: 90px;
    font-size: 3rem;
  }

  .btn {
    margin-top: auto;
  }
}

.vdp-datepicker {
  width: 100%;

  ::v-deep {
    .vdp-datepicker__calendar {
      width: 100%;
      border: 0;

      header {
        > * {
          height: 70px;
          line-height: 70px;
        }

        .up {
          color: $grey;
          font-size: 3rem;
          font-weight: $bold-weight;
          text-transform: uppercase;
        }

        .next {
          &::after {
            border-left-color: $grey;
          }
        }

        .prev {
          &::after {
            border-right-color: $grey;
          }
        }
      }

      .cell {
        height: 75px;
        padding: 0;
        color: $grey;
        font-size: 3.4rem;
        font-weight: $book-weight;
        line-height: 76px;

        &.selected {
          background: none;
        }

        &:hover {
          border: none !important;
        }

        &.day {
          position: relative;
          z-index: 1;
          transition: color $transition-duration-fast ease;

          &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            width: 75px;
            height: 75px;
            transform: translate(-50%, -50%) scale(0);
            transition: transform $transition-duration-fast ease, background-color $transition-duration-fast ease;
            border-radius: 50%;
            background-color: $turquoise;
          }

          &.today {
            &::before {
              transform: translate(-50%, -50%) scale(1);
              background-color: $white-alt;
            }
          }

          &.selected {
            color: $white;

            &::before {
              transform: translate(-50%, -50%) scale(1);
              background-color: $turquoise !important;
            }
          }
        }

        &.month,
        &.year {
          height: 80px;
          margin-top: 20px;
          transition: color $transition-duration-fast ease, background-color $transition-duration-fast ease;
          border-radius: 20px;
          font-size: 3rem;
          line-height: 80px;

          &.selected {
            background-color: $turquoise;
            color: $white;
          }
        }
      }

      .day-header {
        color: $grey;
        font-size: 2rem;
        font-weight: $bold-weight;
        text-transform: uppercase;
      }
    }
  }
}
</style>
