<template>
  <Modal :visible="visible" ref="modal">
    <div class="intro esp">
      <p class="surtitle">{{$t('pays.choices.espagne')}}</p>
      <p class="label">{{$t('modal.espagne.label')}}</p>
    </div>
    <div class="listing scroll-area">
      <div class="inner-scroll-area">
        <div class="region" v-for="region in regions" :key="region.name">
          <input type="radio" :id="`espagne-${region.name}`" :value="region" v-model="newValue">
          <label :for="`espagne-${region.name}`">{{region.name}}</label>
        </div>
      </div>
    </div>
    <button class="btn" @click="onSubmit" :disabled="!newValue" :class="visible">{{$t('valider')}} <i class="icon icon-arrow-right"></i></button>
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal';
import regions from '@/assets/regions-espagne.json';

export default {
  data() {
    return {
      visible: false,
      newValue: this.value,
      regions: regions,
    };
  },
  components: {
    Modal,
  },
  watch: {
    value(newVal) {
      this.newValue = newVal;
    },
  },
  methods: {
    open(value) {
      this.newValue = value;
      this.$refs.modal.open();
    },
    onSubmit() {
      this.$refs.modal.close();
      this.emitNewValue();
    },
    emitNewValue() {
      this.$emit('valueUpdate', this.newValue);
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .modal {
  display: flex;
  overflow: hidden;

  .close {
    width: 30px;
    height: 30px;
  }

  .intro {
    flex: 0 0 auto;
    width: 690px;
    padding: 80px 45px;
    background-image: url('~@/assets/images/mappopin.jpg');
    background-position: center center;
    background-size: cover;

    &.esp {
      background-image: url('~@/assets/images/map-esp.png');
    }

    .surtitle {
      margin-bottom: 40px;
      color: $white;
    }

    .label {
      color: $white;
      font-size: 6.2rem;
      font-weight: $light-weight;
    }
  }

  .listing {
    flex: 1 0 auto;
    padding-left: 50px;

    .region {
      margin-bottom: 30px;
      font-size: 2.5rem;

      input {
        display: none;
      }

      label {
        display: flex;
        align-items: center;
        margin: 0;
        transition: color $transition-duration ease;
        color: $grey;
        font-weight: $regular-weight;
        text-transform: uppercase;
        cursor: pointer;

        &::before {
          content: '';
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-right: 20px;
          transition: background-color $transition-duration ease, border-color $transition-duration ease;
          border: 2px solid $grey;
          border-radius: 50%;
          background-color: transparent;
        }
      }

      input:checked ~ label {
        color: $turquoise;
        // font-weight: $bold-weight;

        &::before {
          border-color: $turquoise;
          background-color: $turquoise;
        }
      }
    }
  }
}

.btn {
  position: absolute;
  z-index: 5;
  right: 30px;
  bottom: 30px;
  transition: opacity $transition-duration-fast ease;
  opacity: 1;

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.listing {
  height: 100%;
}
</style>
