<template>
  <Modal :visible="visible" ref="modal">
    <div class="scroll-area">
      <div class="inner-scroll-area">
        <p class="title">{{$t('confidentialite.title')}}</p>
        <div class="text" v-html="$t('confidentialite.content')"></div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal';

export default {
  data() {
    return {
      visible: false,
    };
  },
  components: {
    Modal,
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
  display: flex;
  width: 900px;
  height: 1100px;
  padding-left: 80px;
  overflow: hidden;

  .scroll-area {
    .inner-scroll-area {
      padding-top: 110px !important;
    }
  }

  .close {
    border: 2px solid;
    vertical-align: middle;
  }
}
</style>
