import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import WordpressAjax from '@/wordpress-ajax';
import i18n from '@/i18n';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';

export default new Vuex.Store({
  state: {
    // questions: [
    //   {
    //     id: 'satisfaction',
    //     type: 'choices',
    //     choices: ['tres-satifsait', 'satisfait', 'peu-satifsait', 'pas-satifsait'],
    //   },
    //   {
    //     id: 'satisfactionSejour',
    //     type: 'choices',
    //     choices: ['tres-satifsait', 'satisfait', 'peu-satifsait', 'pas-satifsait'],
    //   },
    //   {
    //     id: 'pays',
    //     type: 'pays',
    //   },
    //   {
    //     id: 'accompagnement',
    //     type: 'multiChoices',
    //     choices: ['enfants', 'en-couple', 'en-famille', 'voyage-organise', 'seul', 'autre'],
    //   },
    //   {
    //     id: 'transport',
    //     type: 'choices',
    //     choices: ['voiture', 'avion', 'train', 'velo', 'bus', 'autre'],
    //   },
    //   {
    //     id: 'jours',
    //     type: 'jours',
    //   },
    //   {
    //     id: 'hebergement',
    //     type: 'choices',
    //     choices: [
    //       'hotel',
    //       'residence-de-tourisme',
    //       'location-payante',
    //       'amis-famille',
    //       'residence-secondaire',
    //       'de-passage',
    //       'autre',
    //     ],
    //   },
    //   {
    //     id: 'activites',
    //     type: 'multiChoices',
    //     showPass: true,
    //     smallFont: true,
    //     twoCol: true,
    //     choices: [
    //       'balades',
    //       'shopping',
    //       'cite-de-lespace',
    //       'basilique-saint-sernin',
    //       'aeroscopia-airbus',
    //       'halle-de-la-machine',
    //       'envol-des-pioniers',
    //       'museum',
    //       'jacobins',
    //       'petit-train',
    //       'croisiere-garonne',
    //       'bus-panoramique',
    //       'visite-guidee',
    //       'autres',
    //     ],
    //   },
    // ],
    questionsI18n: {
      fr: [],
      en: [],
      es: [],
    },
  },
  getters: {
    questionsOfCurrentLang: state => state.questionsI18n[i18n.locale],
    availableLangs: state => Object.keys(state.questionsI18n).filter(key => state.questionsI18n[key].length > 0),
  },
  mutations: {
    [UPDATE_QUESTIONS](state, { lang, questions }) {
      state.questionsI18n[lang] = questions;
    },
  },
  actions: {
    getQuestionsFromAPI({ commit }, lang) {
      const data = {
        action: 'get_questions',
        lang,
      };

      return WordpressAjax.post(data)
        .then((questions) => {
          commit(UPDATE_QUESTIONS, {
            lang,
            questions,
          });
        });
    },
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
