const debug = process.env.NODE_ENV !== 'production';

const popupShowDelayDefault = 30;
const popupValidateDelayDefault = 30;
const enableTimerDefault = true;

export default {
  // API_URL: debug ? 'https://donjon.w2p.io' : 'https://vps776117.ovh',
  API_URL: debug ? 'https://vps776117.ovh' : 'https://vps776117.ovh',
  API_AJAX_PATH: 'wp-admin/admin-ajax.php',
  popupShowDelay: debug ? 30 : popupShowDelayDefault,
  popupValidateDelay: debug ? 30 : popupValidateDelayDefault,
  enableTimer: debug ? false : enableTimerDefault,
};
