import Vue from 'vue';
import './font-icons/font-icons.font';
import App from './App.vue';
import store from './store';
import i18n from './i18n';

Vue.config.productionTip = false;

new Vue({
  store,
  i18n,
  render: h => h(App, { ref: 'app' }),
  methods: {
    showSelectionModal() {
      this.$refs.app.showSelectionModal();
    },
  },
}).$mount('#app');
