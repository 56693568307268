<template>
  <div class="pays-question" :class="{ 'no-answer': !value }">
    <div key="france" class="choice" @click="showDepartementFranceModal" :class="{selected: currentValue.value === 'france'}">
      <div class="pill">
        {{$t(`${questionID}.choices.france`)}} <i class="flag-icon flag-icon-fr"></i>
        <template v-if="currentValue.value === 'france' && departementFrance">
          ({{departementFrance.code}} - {{departementFrance.name}}) <span class="update-label">- {{$t('modifier')}}</span>
        </template>
      </div>
    </div>

    <div class="choices">
      <div v-for="(icon, id) in pays" :key="id" class="choice" :class="{selected: id === 'espagne' && currentValue.value === 'espagne'}">
        <template v-if="id === 'espagne'">
          <div class="pill" @click="showRegionEspagneModal">
            {{$t(`${questionID}.choices.${id}`)}} <i class="flag-icon" :class="`flag-icon-${icon}`"></i>
            <template v-if="currentValue.value === 'espagne' && regionEspagne">
              ({{regionEspagne.name}}) <span class="update-label">- {{$t('modifier')}}</span>
            </template>
          </div>
        </template>
        <template v-else>
          <input
            type="radio"
            :name="questionID"
            :id="`${questionID}-${id}`"
            :value="id"
            v-model="currentValue.value"
          >
          <label class="pill" :for="`${questionID}-${id}`">{{$t(`${questionID}.choices.${id}`)}} <i class="flag-icon" :class="`flag-icon-${icon}`"></i></label>
        </template>
      </div>
    </div>

    <div key="autre" class="choice" @click="showAutreModal" :class="{selected: currentValue.value === 'autre'}">
      <div class="pill" :for="`${questionID}-autre`">
        {{$t(`${questionID}.choices.autre`)}}&nbsp;
        <span class="specify" v-if="currentValue.value !== 'autre' || !autre">({{$t('preciser')}})</span>
        <template v-if="currentValue.value === 'autre' && autre">
          ({{autre}}) <span class="update-label">- {{$t('modifier')}}</span>
        </template>
      </div>
    </div>

    <DepartementFranceModal
      @valueUpdate="franceUpdate"
      ref="franceModal"
    />

    <RegionEspagneModal
      @valueUpdate="espagneUpdate"
      ref="espagneModal"
    />

    <InputModal
      :label="$t('modal.pays.label')"
      :placeholder="$t('modal.pays.placeholder')"
      @valueUpdate="autreUpdate"
      ref="autreModal"
      type="text"
      keyboardLayout="simple-text"
    />
  </div>
</template>

<script>
import InputModal from '@/components/modals/InputModal';
import RegionEspagneModal from '@/components/modals/RegionEspagneModal';
import DepartementFranceModal from '@/components/modals/DepartementFranceModal';

export default {
  components: {
    RegionEspagneModal,
    DepartementFranceModal,
    InputModal,
  },
  props: {
    questionID: {
      required: true,
    },
    value: Object,
  },
  data() {
    return {
      currentValue: this.value ? this.value : {},
      departementFrance: '',
      regionEspagne: '',
      autre: '',
      pays: {
        allemagne: 'de',
        belgique: 'be',
        canada: 'ca',
        chine: 'cn',
        espagne: 'es',
        italie: 'it',
        japon: 'jp',
        'royaume-uni': 'gb',
        usa: 'us',
      },
    };
  },
  watch: {
    currentValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    showDepartementFranceModal() {
      this.$refs.franceModal.open(this.departementFrance);
    },
    showRegionEspagneModal() {
      this.$refs.espagneModal.open(this.regionEspagne);
    },
    showAutreModal() {
      this.$refs.autreModal.open(this.autre);
    },
    franceUpdate(value) {
      this.departementFrance = value;

      this.currentValue = {
        value: 'france',
        extraText: `${this.departementFrance.code} - ${this.departementFrance.name}`,
        extra: this.departementFrance,
      };
    },
    espagneUpdate(value) {
      this.regionEspagne = value;

      this.currentValue = {
        value: 'espagne',
        extraText: `${this.regionEspagne.name}`,
        extra: this.regionEspagne,
      };
    },
    autreUpdate(value) {
      this.autre = value;

      this.currentValue = {
        value: 'autre',
        extra: this.autre,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pays-question {
  margin-top: 100px;
  margin-right: -40px;

  .choices {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &:not(.no-answer) {
    .choice {
      .pill {
        opacity: 0.4;
      }

      input {
        &:checked {
          ~ .pill {
            opacity: 1;
            background-color: $white;
            color: $grey;
          }
        }
      }

      &.selected {
        .pill {
          opacity: 1;
          background-color: $white;
          color: $grey;
        }
      }
    }
  }
}

.choice {
  display: inline-block;
  width: auto;
  margin-right: 25px;
  margin-bottom: 25px;

  input {
    display: none;
  }

  .pill {
    transition: opacity $transition-duration ease;

    .flag-icon {
      margin-left: 10px;
      font-size: 1.7rem;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
</style>
