<template>
  <div class="keyboard-container" :class="{ layout, 'hide-enter': !keyboardAllowEnter }">
    <div :class='keyboardClass'></div>
  </div>
</template>

<script>
import Keyboard from 'simple-keyboard';

export default {
  name: 'SimpleKeyboard',
  props: {
    keyboardClass: {
      type: String,
      default: 'simple-keyboard',
    },
    keyboardAllowEnter: {
      type: Boolean,
      default: false,
    },
    input: {
      type: String,
    },
    layout: {
      default: 'email',
      type: String,
    },
  },
  data() {
    return {
      keyboard: null,
      layouts: {
        email: [
          '@ .com .fr - _ {bksp}',
          '1 2 3 4 5 6 7 8 9 0',
          'a z e r t y u i o p',
          'q s d f g h j k l m',
          'w x c v b n . {submit}',
        ],
        'simple-text': [
          'é è à \' ç ù , . {bksp}',
          'a z e r t y u i o p',
          'q s d f g h j k l m',
          'w x c v b n {submit}',
        ],
        text: [
          'a z e r t y u i o p',
          'q s d f g h j k l m',
          '{shift} w x c v b n \' {bksp}',
          '{number} , {space} . {enter} {submit}',
        ],
        shift: [
          'A Z E R T Y U I O P',
          'Q S D F G H J K L M',
          '{shift} W X C V B N ? {bksp}',
          '{number} , {space} . {enter} {submit}',
        ],
        number: [
          '1 2 3 4 5 6 7 8 9 0',
          '@ # € _ & - + ( ) /',
          '{shift} * " \' : ; ! ? {bksp}',
          '{number} , {space} . {enter} {submit}',
        ],
      },
    };
  },
  mounted() {
    this.keyboard = new Keyboard({
      theme: 'custom',
      layout: this.layouts,
      layoutName: this.layout,
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      newLineOnEnter: this.keyboardAllowEnter,
      mergeDisplay: true,
      display: {
        '{bksp}': '<i class="icon icon-arrow-left"></i>',
        '{submit}': `${this.$i18n.t('valider')} <i class="icon icon-arrow-right"></i>`,
        '{shift}': '<i class="icon icon-arrow-up"></i>',
        '{enter}': '<i class="icon icon-arrow-enter"></i>',
        '{number}': '?123',
      },
      buttonTheme: [{
        class: 'large',
        buttons: '@gmail.com @hotmail.fr',
      }, {
        class: 'medium',
        buttons: '.fr .com {bksp}',
      }, {
        class: 'downlight',
        buttons: '@gmail.com @hotmail.fr {bksp} .com .fr {shift} {enter} {space} {number} ? , . - _ @ é è à É È À \' ç ù Ç Ù 1 2 3 4 5 6 7 8 9 0 ! ( ) ; :',
      }, {
        class: 'special-chars',
        buttons: '- _ @ é è à É È À \' ç ù Ç Ù 1 2 3 4 5 6 7 8 9 0 ! ( ) ; :',
      }],
    });

    this.keyboard.setInput(this.input);
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input);
    },
    onKeyPress(button) {
      if (button === '{submit}') this.$emit('submit');
      // if (button === '{enter}') this.onChange(`${this.input}&nbsp;\n\r`);
      this.$emit('onKeyPress', button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}') this.handleShift();
      if (button === '{number}') this.handleNumber();
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === 'shift' ? 'text' : 'shift';
      this.previousLayout = currentLayout;

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    handleNumber() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === 'number' ? 'text' : 'number';

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
};
</script>

<style lang='scss' scoped>
$button-size: 120px;
$buttons-spacing: 20px;
$spacing: $buttons-spacing * 2;
$total-width: ($button-size * 10) + ($buttons-spacing * 9) + ($spacing * 2);

.keyboard-container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: linear-gradient(90deg, #A92B8D 0%, #E92F89 35.94%, #E9242D 70.83%, #F07830 100%);

  &.hide-enter {
    ::v-deep .hg-button-enter {
      display: none !important;
    }
  }
}

.simple-keyboard {
  position: relative;
  width: $total-width;
  margin: auto;
  padding: $spacing $spacing - $buttons-spacing $spacing - $buttons-spacing $spacing;

  ::v-deep .hg-row {
    display: flex;
  }

  ::v-deep .hg-button[data-skbtn='@'] {
    font-size: 0.375 * $button-size;
    font-weight: $bold-weight;
  }

  &.hg-layout-email ::v-deep,
  &.hg-layout-simple-text ::v-deep {
    .hg-button {
      text-transform: uppercase;
    }
  }

  &.hg-layout-email ::v-deep {
    .hg-row {
      &:first-child,
      &:nth-child(2) {
        .hg-button {
          height: $button-size * 0.333;
          font-size: 0.25 * $button-size;
        }
      }
    }
  }

  &.hg-layout-number ::v-deep {
    .hg-button.special-chars {
      background-color: rgba($white, 0.25);
      font-size: 0.375 * $button-size;
    }
  }

  ::v-deep .hg-button {
    display: flex;
    position: relative;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: $button-size;
    height: $button-size;
    // border: $buttons-spacing / 2 solid $white;
    margin: 0 $buttons-spacing $buttons-spacing 0;
    transition: background-color $transition-duration ease;
    border-radius: 15px;
    background-color: rgba($white, 0.25);
    color: $white;
    font-size: 0.275 * $button-size;
    // box-shadow: 0 2px 4px rgba($black, 0.1);

    &.large {
      flex: 2 1 auto;
      width: auto;
    }

    &.medium {
      flex: 1 1 auto;
      width: auto;
    }

    &:not(.downlight) {
      font-size: 0.375 * $button-size;
    }

    &.downlight {
      background-color: rgba($white, 0.1);
      // color: $black;
    }

    &.hg-button-space {
      flex: 3 1 auto;
      width: auto;
    }

    &.hg-button-submit {
      flex: 1 1 auto;
      width: auto;
      max-width: (($button-size + $buttons-spacing) * 4) - $buttons-spacing;
      background-color: $turquoise;
      font-size: 0.25 * $button-size;

      .icon {
        margin-left: 20px;
      }
    }

    &.hg-button-enter {
      flex: 1 1 auto;
      width: auto;
      max-width: (($button-size + $buttons-spacing) * 4) - $buttons-spacing;
    }

    &:active {
      background-color: rgba($white, 0.5);
    }
  }
}

</style>
