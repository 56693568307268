<template>
  <div class="choices-question">
    <div class="checkbox-choice" v-for="choice in choices" :key="choice.id" :class="{twoCol: choices.length > 8 }">
        <input
          type="checkbox"
          :name="questionID"
          :id="`${questionID}-${choice.id}`"
          :value="choice.id"
          v-model="currentValue.value"
        >
        <label class="label" :for="`${questionID}-${choice.id}`">{{choice.display_name}}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questionID: {
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
    value: Object,
  },
  data() {
    return {
      currentValue: this.value ? this.value : {
        value: [],
      },
    };
  },
  watch: {
    'currentValue.value'(newValue) {
      this.$emit('input', { value: newValue });
    },
  },
};
</script>
<style lang="scss" scoped>
.choices-question {
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
}

.checkbox-choice {
  width: 100%;
  margin-bottom: 30px;
  padding-right: 50px;

  &.twoCol {
    width: 50%;
  }

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    margin: 0;
    transition: color $transition-duration ease;
    color: $white;
    font-weight: $book-weight;
    text-transform: uppercase;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 17px;
      margin-bottom: 5px;
      transition: background-color $transition-duration ease, border-color $transition-duration ease;
      border: 1px solid $white;
      border-radius: 5px;
      background-color: transparent;
    }
  }

  input:checked ~ label {
    // color: $turquoise;
    font-weight: $bold-weight;

    &::before {
      border-color: $turquoise;
      background-color: $turquoise;
    }
  }
}
</style>
