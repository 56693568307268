<template>
  <div class="text-question" :class="{ 'no-answer': !value }">
    <div class="pill" :for="`${questionID}-plus`" @click="showModal">
      <i class="icon icon-edit"></i>
      <span v-if="!currentValue.value">{{$t('text.label')}}</span>
      <span class="value" v-if="currentValue.value">{{currentValue.value}}</span>
    </div>

    <InputModal
      :label="questionLabel"
      :placeholder="$t('text.placeholder')"
      @valueUpdate="modalUpdate"
      ref="modal"
      type="text"
      keyboardLayout="text"
    />
  </div>
</template>

<script>
import InputModal from '@/components/modals/InputModal';

export default {
  components: {
    InputModal,
  },
  props: {
    questionID: {
      required: true,
    },
    questionLabel: {
      type: String,
      required: true,
    },
    value: Object,
  },
  data() {
    return {
      currentValue: this.value ? this.value : {},
    };
  },
  watch: {
    currentValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    showModal() {
      this.$refs.modal.open(this.currentValue.value);
    },
    modalUpdate(value) {
      this.currentValue = {
        value: value,
      };
    },
  },
};
</script>

<style lang="scss" scoped>

.pill {
  .icon-edit {
    margin-right: 20px;
    margin-left: -10px;
  }

  .value {
    text-transform: none;
  }
}

</style>
