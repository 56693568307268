<template>
  <div class="finish">
    <img class="img img-1" src="../assets/images/fin1.png" alt="">
    <img class="img img-2" src="../assets/images/fin2.png" alt="">

    <div class="finish-bloc">
      <div class="loader" :class="{ 'js-updating': loading }">
        <div class="spinner"></div>
      </div>

      <transition name="slide-fade" mode="out-in">
        <div class="content" key="intro" v-if="!send">
          <h2 class="modal-title" v-html="$t('finish.title')"></h2>
          <div class="input-container" @click="showEmailModal">
            <input type="email" id="mail" :placeholder="$t('finish.placeholder')" disabled="disabled" v-model="emailInput" ref="input">
            <p class="error" v-if="error">{{$t(`finish.error.${error}`)}}</p>
          </div>
          <p class="confidentialite" v-html="$t('finish.confidentialite')" @click="$refs.confidentitaliteModal.open()"></p>
          <button class="btn" @click="submit">{{$t('envoyer')}}<i class="icon icon-send"></i></button>
        </div>
        <div class="content" key="send" v-else>
          <img class="img-valid" src="../assets/images/valid.svg" alt="">
          <h3 class="mail-ok">{{$t('finish.mailOk')}}</h3>
          <button @click="goToHome" class="btn btn-lrg">{{$t('accueil')}}</button>
        </div>
      </transition>
    </div>

    <img class="logo" alt="Logo Toulouse" src="../assets/images/logo-toulouse-white.svg">

    <ConfidentialiteModal
      ref="confidentitaliteModal"
    />
    <InputModal
      :label="$t('finish.email-modal.label')"
      :placeholder="$t('finish.email-modal.placeholder')"
      type="email"
      keyboardLayout="email"
      @valueUpdate="emailUpdate"
      ref="emailModal"
    />
  </div>
</template>

<script>
import ConfidentialiteModal from '@/components/modals/ConfidentialiteModal';
import InputModal from '@/components/modals/InputModal';
import WordpressAjax from '@/wordpress-ajax';
import utils from '@/utils';

export default {
  components: {
    ConfidentialiteModal,
    InputModal,
  },
  data() {
    return {
      send: false,
      emailInput: '',
      loading: false,
      error: false,
    };
  },
  props: {
    answers: Array,
  },
  methods: {
    goToHome() {
      this.$emit('homeClick');
    },
    submit() {
      if (!utils.validateEmail(this.emailInput)) {
        this.error = 'wrong-email';
        return false;
      }
      this.error = false;
      this.loading = true;

      const data = {
        action: 'save_enquete',
        data: {
          lang: this.$i18n.locale,
          email: this.emailInput,
        },
      };

      return WordpressAjax.post(data)
        .then(() => {
          this.loading = false;
          this.send = true;
        })
        .catch(() => {
          this.loading = false;
          this.error = 'default';
        });
    },
    showEmailModal() {
      this.$refs.emailModal.open(this.emailInput);
    },
    emailUpdate(value) {
      this.emailInput = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.finish {
  position: relative;
  width: 100%;
  height: 100%;

  .img {
    position: absolute;

    &.img-1 {
      bottom: 100px;
      left: 220px;
      width: 370px;
    }

    &.img-2 {
      top: 0;
      right: 0;
      width: 600px;
    }
  }

  .finish-bloc {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 900px;
    height: 930px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    background-color: $white;

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 130px 100px 90px;
    }

    .loader {
      display: flex;
      position: absolute;
      z-index: 50;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 3rem 0;
      transition: opacity $transition-duration ease;
      opacity: 0;
      background-color: rgba($white, 0.9);
      color: $white;
      pointer-events: none;

      &.js-updating {
        opacity: 1;
        pointer-events: all;
      }

      .spinner {
        width: 50px;
        height: 50px;
        margin: auto;
        animation: rotate 2s infinite linear;
        border: 5px solid $pink;
        border-radius: 50%;
        border-top-color: rgba($pink, 0.2);
      }

      @keyframes rotate {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }
    }

    .modal-title {
      margin-bottom: 60px;
      font-size: 6rem;
      font-weight: $light-weight;
      line-height: 1.2;
    }

    .btn {
      width: 400px;
      margin: auto;
      // margin-left: 0;
    }

    .btn-lrg {
      margin-left: auto;
      padding: 6px 90px 8px;
    }

    .mail-ok {
      margin: auto;
      margin-bottom: 30px;
      padding: 0 50px;
      color: $greydark;
      font-size: 6.2rem;
      font-weight: $light-weight;
      text-align: center;
    }

    .img-valid {
      width: 100px;
      margin: auto;
      margin-bottom: 0;
    }

    .input-container {
      position: relative;
      margin-bottom: 60px;

      input {
        width: 100%;
        padding-bottom: 15px;
        border: 0;
        border-bottom: 2px solid #9B9FAE6B;
        outline: none;
        background: transparent;
        font-size: 3.5rem;

        &:focus {
          outline: none;
        }

        @include placeholder {
          opacity: 0.3;
        }
      }

      .error {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        color: $pink;
        font-size: 2rem;
        font-size: $regular-weight;
      }
    }

    .confidentialite {
      padding-right: 90px;
      color: $grey-alt;
      font-size: 2rem;
      font-weight: $book-weight;

      ::v-deep span {
        color: $pink;
      }
    }
  }

  .logo {
    position: absolute;
    right: -40px;
    bottom: 45px;
    width: 190px;
    max-width: 100%;
    height: auto;
    transform: translateX(-50%);
  }

  ::v-deep .modal-open {
    .title {
      color: $greydark;
      font-size: 4.2rem;
      font-weight: $light-weight;
    }

    .text {
      color: $grey-alt;
      font-size: 2.3rem;
      font-weight: $book-weight;
    }

    .scroll-area .inner-scroll-area {
      padding: 0;
      padding-right: 110px;
      padding-bottom: 100px;
    }
  }
}

// .menu button
.slide-fade-enter-active {
  transition: all $transition-duration ease;
}

.slide-fade-leave-active {
  transition: all $transition-duration ease;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
