<template>
  <div class="text-question" :class="{ 'no-answer': !value }">
    <div class="pill textarea" :for="`${questionID}-plus`" @click="showModal">
      <i class="icon icon-edit"></i>
      <span v-if="!currentValue.value">{{$t('textarea.label')}}</span>
      <span class="value" v-if="currentValue.value" v-html="currentValue.value"></span>
    </div>

    <InputModal
      :label="questionLabel"
      :placeholder="$t('textarea.placeholder')"
      @valueUpdate="modalUpdate"
      ref="modal"
      type="textarea"
      keyboardLayout="text"
      :keyboardAllowEnter="true"
    />
  </div>
</template>

<script>
import InputModal from '@/components/modals/InputModal';

export default {
  components: {
    InputModal,
  },
  props: {
    questionID: {
      required: true,
    },
    questionLabel: {
      type: String,
      required: true,
    },
    value: Object,
  },
  data() {
    return {
      currentValue: this.value ? this.value : {},
    };
  },
  watch: {
    currentValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    showModal() {
      this.$refs.modal.open(this.currentValue.value);
    },
    modalUpdate(value) {
      this.currentValue = {
        value: value,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  display: block;
  position: relative;
  width: 100%;
  height: 400px;
  padding-top: 40px;
  padding-left: 100px;
  border-radius: 15px;

  .value {
    line-height: 1.6;
    text-transform: none;
    white-space: pre-line;
  }

  .icon-edit {
    position: absolute;
    top: 40px;
    left: 50px;
    margin-right: 20px;
    margin-left: -10px;
  }
}

</style>
