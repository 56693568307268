<template>
  <Modal :visible="visible" ref="modal">
    <p class="surtitle">{{title}}</p>

    <div class="jours-input">
      <button class="minus" @click="minusClick" :class="{ disabled: this.newValue === 4 }">-</button>
      <span class="value">{{newValue}}</span>
      <button class="plus" @click="plusClick">+</button>
      <span class="label" v-if="suffix">{{suffix}}</span>
    </div>

    <button class="btn" @click="onSubmit" :disabled="!newValue" :class="visible">{{$t('valider')}} <i class="icon icon-arrow-right"></i></button>
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal';

export default {
  props: {
    title: {
      required: true,
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      newValue: this.value ? this.value : 4,
    };
  },
  components: {
    Modal,
  },
  watch: {
    value(newVal) {
      this.newValue = newVal;
    },
  },
  methods: {
    open(value) {
      this.newValue = value;
      this.$refs.modal.open();
    },
    onSubmit() {
      this.$refs.modal.close();
      this.emitNewValue();
    },
    emitNewValue() {
      this.$emit('valueUpdate', this.newValue);
    },
    onInputChange(value) {
      this.newValue = value;
    },
    emptyInput() {
      this.newValue = '';
    },
    plusClick() {
      this.newValue += 1;
    },
    minusClick() {
      if (this.newValue === 4) {
        return;
      }
      this.newValue -= 1;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 750px;
  height: 850px;
  padding: 80px;
  text-align: center;

  .surtitle {
    margin-top: 90px;
    font-size: 3rem;
  }

  .jours-input {
    display: flex;
    align-items: center;
    margin-top: 100px;
    color: $grey;
    font-size: 5rem;
    font-weight: $light-weight;

    .value {
      width: 90px;
      color: $turquoise;
    }

    .label {
      margin-left: 20px;
    }

    .plus,
    .minus {
      width: 80px;
      height: 80px;
      margin-top: -5px;
      transition: opacity $transition-duration-fast ease;
      border: 2px solid;
      border-radius: 50%;
      background: transparent;
      color: $turquoise;
      font-size: 4rem;
      font-weight: $regular-weight;

      &.disabled {
        opacity: 0.3;
      }
    }
  }

  .btn {
    margin-top: 120px;
  }
}
</style>
