<template>
  <div class="question-container">
    <p class="title" v-html="question.display_name" :class="{ small: question.display_name.length > 70 }"></p>
    <!-- {{question.html_type}} -->
    <TextareaQuestion
      v-if="question.html_type === 'textarea'"
      :questionID="question.id"
      :questionLabel="question.display_name"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
    />
    <MatrixQuestion
      v-else-if="question.html_type === 'matrix'"
      :questionID="question.id"
      :questionLabel="question.display_name"
      :choices="question.choices"
      :lines="question.lines"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
    />
    <DateQuestion
      v-else-if="question.html_type === 'date'"
      :questionID="question.id"
      :questionLabel="question.display_name"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
    />
    <NumberQuestion
      v-else-if="question.html_type === 'number'"
      :questionID="question.id"
      :questionLabel="question.display_name"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
    />
    <ChoicesQuestion
      v-else-if="question.html_type === 'radio'"
      :questionID="question.id"
      :choices="question.choices"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
    />
    <MultipleChoicesQuestion
      v-else-if="question.html_type === 'checkbox'"
      :questionID="question.id"
      :choices="question.choices"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
    />
    <PaysQuestion
      v-else-if="question.html_type === 'pays'"
      :questionID="question.id"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
    />
    <JoursQuestion
      v-else-if="question.html_type === 'jours'"
      :questionID="question.id"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
    />
    <TextQuestion
      v-else
      :questionID="question.id"
      :questionLabel="question.display_name"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import TextQuestion from '@/components/TextQuestion';
import TextareaQuestion from '@/components/TextareaQuestion';
import DateQuestion from '@/components/DateQuestion';
import ChoicesQuestion from '@/components/ChoicesQuestion';
import MultipleChoicesQuestion from '@/components/MultipleChoicesQuestion';
import PaysQuestion from '@/components/PaysQuestion';
import JoursQuestion from '@/components/JoursQuestion';
import NumberQuestion from '@/components/NumberQuestion';
import MatrixQuestion from '@/components/MatrixQuestion';

export default {
  components: {
    ChoicesQuestion,
    MultipleChoicesQuestion,
    PaysQuestion,
    JoursQuestion,
    TextQuestion,
    TextareaQuestion,
    DateQuestion,
    NumberQuestion,
    MatrixQuestion,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {},
  },
};
</script>
<style lang="scss" scoped>
.question-container {
  padding: 50px 150px;
}

.title {
  color: $white;
  font-size: 8rem;
  font-weight: $light-weight;

  &.small {
    font-size: 5rem;
    line-height: 1.3;
  }
}
</style>
