<template>
  <div class="choices-question" :class="{ 'no-answer': !value }">
    <div v-for="choice in choices" :key="choice.id" class="choice">
      <input
        type="radio"
        :name="questionID"
        :id="`${questionID}-${choice.id}`"
        :value="`${choice.id}`"
        v-model="currentValue.value"
      >
      <label class="pill" :class="{ number: choice.display_name.length <= 2}" :for="`${questionID}-${choice.id}`">{{choice.display_name}}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questionID: {
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
    value: Object,
  },
  data() {
    return {
      currentValue: this.value ? this.value : {},
    };
  },
  watch: {
    currentValue(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.choices-question {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 500px;
  margin-top: 100px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &:not(.no-answer) {
    .choice {
      label {
        opacity: 0.4;
      }

      input {
        &:checked {
          ~ label {
            opacity: 1;
            background-color: $white;
            color: $grey;
          }
        }
      }
    }
  }
}

.choice {
  margin-right: 25px;
  margin-bottom: 25px;

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    transition: opacity $transition-duration ease;
  }
}
</style>
