<template>
  <div class="choices-question" :class="{ 'no-answer': !value }">
    <div class="choices">
      <div v-for="id in choices" :key="id" class="choice">
        <input
          type="radio"
          :name="questionID"
          :id="`${questionID}-${id}`"
          :value="id"
          v-model="currentValue.value"
        >
        <label class="pill" :for="`${questionID}-${id}`">{{$t(`${questionID}.choices.${id}`)}}</label>
      </div>

      <div key="plus" class="choice" @click="showPlusNumberModal" :class="{selected: currentValue.value === 'plus'}">
        <div class="pill" :for="`${questionID}-plus`">

          <span class="specify" v-if="currentValue.value !== 'plus' || !plus">{{$t(`${questionID}.choices.plus`)}} ({{$t('preciser')}})</span>
          <template v-if="currentValue.value === 'plus' && plus">
            {{plus}} {{$t('modal.jours.jours-label')}} <span class="update-label">- {{$t('modifier')}}</span>
          </template>
        </div>
      </div>
    </div>

    <PlusNumberModal
      @valueUpdate="plusUpdate"
      :title="$t('modal.jours.label')"
      :suffix="$t('modal.jours.jours-label')"
      ref="plusModal"
    />
  </div>
</template>

<script>
import PlusNumberModal from '@/components/modals/PlusNumberModal';

export default {
  components: {
    PlusNumberModal,
  },
  props: {
    questionID: {
      required: true,
    },
    value: Object,
  },
  data() {
    return {
      currentValue: this.value ? this.value : {},
      plus: 4,
      choices: [
        '1-jour',
        '2-jours',
        '3-jours',
      ],
    };
  },
  watch: {
    currentValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    showPlusNumberModal() {
      this.$refs.plusModal.open(this.plus);
    },
    plusUpdate(value) {
      this.plus = value;

      this.currentValue = {
        value: 'plus',
        extra: this.plus,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.choices-question {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 150px;

  &:not(.no-answer) {
    .choice {
      .pill {
        opacity: 0.4;
      }

      input {
        &:checked {
          ~ .pill {
            opacity: 1;
            background-color: $white;
            color: $grey;
          }
        }
      }

      &.selected {
        .pill {
          opacity: 1;
          background-color: $white;
          color: $grey;
        }
      }
    }
  }

  // .modal {
  //   width: 600px;
  //   height: 650px;

  //   .surtitle {
  //     font-size: 3rem;
  //   }
  // }
}

.choices {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.choice {
  margin-right: 25px;
  margin-bottom: 25px;

  input {
    display: none;
  }

  .pill {
    transition: opacity $transition-duration ease;
  }
}
</style>
